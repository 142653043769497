import React from 'react'
import { Link } from '@wapps/gatsby-plugin-i18next'
import { useTranslation } from 'react-i18next'

import { Flex } from '@components/Grid'
import LanguageSwitcher from '@components/LanguageSwitcher'

import Wrapper from './Wrapper'
import HeaderContent from './HeaderContent'
import Menu from './Menu'
import MenuItem from './MenuItem'
import Logo from './Logo'
import Banner from './Banner'
import { Text } from '@components/Text'

const Header = () => {
  const { t } = useTranslation()
  return (
    <>
    <Wrapper>
      <HeaderContent>
        <Flex flex="1 1 180px">
          <Link to="/">
            <Logo>
              <Text fontWeight="heading">of</Text>
              <Text fontWeight="normal">spots</Text>
            </Logo>
          </Link>
        </Flex>

        <Menu>
          {/* <MenuItem to="/case-studies">{t('HEADER_MENUITEM_CASE_STUDIES')}</MenuItem>
          <MenuItem to="/about">{t('HEADER_MENUITEM_ABOUT')}</MenuItem> */}
        </Menu>

        <LanguageSwitcher />
      </HeaderContent>
    </Wrapper>
    </>
  )
}

Header.propTypes = {}

export default Header
