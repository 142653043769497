import styled from 'styled-components'
import { themeGet } from '@style'

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 0 solid rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  background-color: ${themeGet('colors.deepSafron')};
  padding: 0 ${themeGet('space.4')};
`

export default Wrapper
