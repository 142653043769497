import styled from 'styled-components'

const Menu = styled.ul`
  min-height: 100%;
  display: flex;
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
`

export default Menu
