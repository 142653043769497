/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { Language } from '@wapps/gatsby-plugin-i18next'

import { Flex, Box } from '@components/Grid'
import { Text } from '@components/Text'
import { Heighlight } from '@components/Heighlight'

const LanguageSwitcher = ({ changeLng, lng, availableLngs }) => {
  return (
  <Flex>
    {availableLngs.map((value) => {
      return (
        <>
        {
        lng === value ? <Heighlight onClick={() => changeLng(value)} css={{ cursor: 'pointer' }} px={1}>{value}</Heighlight> :
        <Text onClick={() => changeLng(value)} css={{ cursor: 'pointer' }} px={1}>{value}</Text>
      }
        </>
    )
    })}

  </Flex>
  )
}
LanguageSwitcher.propTypes = {
  changeLng: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
  availableLngs: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const LanguageWrapper = (props) => (
  <Language>
    {(lngProps) => <LanguageSwitcher {...props} {...lngProps} />}
  </Language>
)

export default LanguageWrapper
