import styled from '@style'

const Logo = styled.div`
  display: flex;
  background-color: transparent;
  width: 120px;
  color: #813405;
  font-size: 18px;
`

export default Logo
