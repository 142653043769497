import styled from 'styled-components'
import { themeGet } from '@style'

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: transparent;
  flex-direction: row;
  align-items: center;
  padding: ${themeGet('space.3')} 0;
  justify-content: space-between;
  color: #333;
`

export default HeaderContent
