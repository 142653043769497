import styled from 'styled-components'
import { Link } from '@wapps/gatsby-plugin-i18next'

const MenuItem = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: inherit;
  letter-spacing: 1px;
  padding: 12px 14px;
  position: relative;
  text-transform: uppercase;
  text-shadow: 0 6px 15px rgba(36, 37, 38, 0.1);
  cursor: pointer;
`

export default MenuItem
