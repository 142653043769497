import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import { Layout } from '@components/Layout'
import Header from '@components/Header'
import Section from '@components/Section'
import { Flex, Box } from '@components/Grid'
import { SEO } from '@components/SEO'

import { withI18next } from '../i18n'

import Container from './Container'

const Template = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark
  const image = getImage(markdownRemark.frontmatter.image)

  return (
    <Layout>
      <SEO
        title={markdownRemark.frontmatter.title}
        pathname={markdownRemark.frontmatter.slug}
        description={markdownRemark.frontmatter.title}
      />

      <Header />
      <GatsbyImage image={image} />

      <Box bg="saddleBrown" height="16px"></Box>

      <Container dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!, $lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }

    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 800
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`

Template.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export default withI18next()(Template)
